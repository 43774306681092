<template>
  <v-container class="px-6 d-block" fluid>
    <div class="d-flex align-center mb-6">
      <v-icon size="15" color="#4ab762">mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-0 ml-2">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-row>
      <v-col cols="12">
        <v-card
          class="rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px"
        >
          <v-card-title
            class="subtitle-1 grey--text text--darken-2 font-weight-bold"
            >Data Voucher</v-card-title
          >
          <v-card-text class="px-0 py-0">
            <div class="px-4 d-flex justify-space-between">
              <v-btn-toggle dense class="mb-2">
                <v-btn
                  color="#2ecc71"
                  class="text-none"
                  text
                  small
                  :disabled="loading"
                  @click="dialog.voucher = true"
                >
                  <v-icon size="16" color="#2ecc71"> mdi-plus-box </v-icon>
                  <span class="hidden-sm-and-down ml-1">Tambah</span>
                </v-btn>

                <v-btn
                  color="#7f8c8d"
                  :disabled="loading || selected.voucher.length <= 0 || !selected.voucher[0].updateable_bool"
                  class="text-none"
                  text
                  small
                  @click="
                    setForm();
                    dialog.voucher = true;
                  "
                >
                  <v-icon size="16" color="#7f8c8d"> mdi-pencil-box </v-icon>
                  <span class="hidden-sm-and-down ml-1">Ubah</span>
                </v-btn>

                <v-btn
                  color="#3498db"
                  class="text-none"
                  :disabled="loading || selected.voucher.length <= 0"
                  text
                  small
                  @click="fetchDetail(selected.voucher[0]); dialog.detail = true"
                >
                  <v-icon size="16" color="#3498db"> mdi-information </v-icon>
                  <span class="hidden-sm-and-down ml-1">Detail</span>
                </v-btn>

                <v-btn
                  color="#e74c3c"
                  class="text-none"
                  :disabled="loading || selected.voucher.length <= 0"
                  text
                  small
                  @click="
                    setForm();
                    dialog.confirmDelete = true;
                  "
                >
                  <v-icon size="16" color="#e74c3c">
                    mdi-delete-forever
                  </v-icon>
                  <span class="hidden-sm-and-down ml-1">Hapus</span>
                </v-btn>
              </v-btn-toggle>
              <!-- <v-btn-toggle dense class="mb-2">
                <v-btn
                  color="#27ae60"
                  class="text-none"
                  text
                  small
                  :disabled="loading"
                  @click="exportData"
                >
                  <v-icon size="16" color="#27ae60"> mdi-file-excel </v-icon>
                  <span class="hidden-sm-and-down ml-1">Export Excel</span>
                </v-btn>
              </v-btn-toggle> -->
            </div>
            <v-data-table
              v-model="selected.voucher"
              :headers="headers.voucher"
              :items="dataGrid.voucher"
              :loading="loading"
              :options.sync="options.voucher"
              :server-items-length="totalData.voucher"
              loading-text="Sedang memuat"
              :no-data-text="'Data belum ada'"
              no-results-text="Data belum ada"
              sort-by-text="Urutkan berdasarkan"
              :items-per-page="10"
              height="calc(100vh - 340px)"
              fixed-header
              :footer-props="footerProps"
              item-key="id"
              flat
              single-select
              show-select
              class="elevation-0 custom-grid rounded-lg"
            >
              <template v-slot:[`item.item_type`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(241 196 15);
                    background-color: rgb(241 196 15 / 10%);
                  "
                  v-if="item.item_type == 'all category'"
                >
                  Semua Kategori
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(46 204 113);
                    background-color: rgb(46 204 113 / 10%);
                  "
                  v-if="item.item_type == 'all course'"
                >
                  Semua Kelas
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                  v-if="item.item_type == 'all organization'"
                >
                  Semua Organisasi
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(241 196 15);
                    background-color: rgb(241 196 15 / 10%);
                  "
                  v-if="item.item_type == 'category'"
                >
                  Kategori Tertentu
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(46 204 113);
                    background-color: rgb(46 204 113 / 10%);
                  "
                  v-if="item.item_type == 'course'"
                >
                  Kelas Tertentu
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                  v-if="item.item_type == 'organization'"
                >
                  Organisasi Tertentu
                </v-chip>
              </template>

              <template v-slot:[`item.platform`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(241 196 15);
                    background-color: rgb(241 196 15 / 10%);
                  "
                  v-if="item.platform == 'mobile'"
                >
                  Aplikasi Mobile
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                  v-if="item.platform == 'web'"
                >
                  Aplikasi Web
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(149 165 166);
                    background-color: rgb(149 165 166 / 10%);
                  "
                  v-if="item.platform == 'semua'"
                >
                  Semua Aplikasi
                </v-chip>
              </template>

              <template v-slot:[`item.start_datetime`]="{ item }">
                {{ item.start_datetime | datetime }}
              </template>

              <template v-slot:[`item.end_datetime`]="{ item }">
                {{ item.end_datetime | datetime }}
              </template>

              <template v-slot:[`item.quota`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 73 94);
                    background-color: rgb(52 73 94 / 10%);
                  "
                >
                  {{
                    item.quota > 0
                      ? item.quota_used +
                        " / " +
                        item.quota +
                        " Voucher Terpakai"
                      : "Unlimited"
                  }}
                </v-chip>
              </template>

              <template v-slot:[`item.user_limit`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(155 89 182);
                    background-color: rgb(155 89 182 / 10%);
                  "
                >
                  {{ item.quota > 0 ? item.user_limit + " Kali" : "Unlimited" }}
                </v-chip>
              </template>

              <template v-slot:[`item.discount_percent`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 152 219);
                    background-color: rgb(52 152 219 / 10%);
                  "
                >
                  <v-icon small left color="rgb(52 152 219)">mdi-sale</v-icon
                  >{{ item.discount_percent + "%" }}
                </v-chip>
              </template>

              <template v-slot:[`item.discount_max`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 73 94);
                    background-color: rgb(52 73 94 / 10%);
                  "
                >
                  <span v-if="item.discount_max > 0"
                    >Rp{{ item.discount_max | pricing }}</span
                  >
                  <span v-else>Unlimited</span>
                </v-chip>
              </template>

              <template v-slot:[`item.min_order`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(52 73 94);
                    background-color: rgb(52 73 94 / 10%);
                  "
                >
                  <span v-if="item.min_order > 0"
                    >Rp{{ item.min_order | pricing }}</span
                  >
                  <span v-else>Tidak ada minimal</span>
                </v-chip>
              </template>

              <template v-slot:[`item.active_bool`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(46 204 113);
                    background-color: rgb(46 204 113 / 10%);
                  "
                  v-if="item.active_bool"
                >
                  Aktif
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(231, 76, 60);
                    background-color: rgb(231 76 60 / 10%);
                  "
                  v-else
                >
                  Tidak Aktif
                </v-chip>
              </template>

              <!-- <template v-slot:[`item.quota_used`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(231, 76, 60);
                    background-color: rgb(231 76 60 / 10%);
                  "
                >
                  {{ item.quota > 0 ? item.quota + ' Voucher' : 'Unlimited' }}
                </v-chip>
              </template>

              <template v-slot:[`item.quota_balance`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(243 156 18);
                    background-color: rgb(243 156 18 / 10%);
                  "
                >
                  {{ item.quota_balance }} Voucher
                </v-chip>
              </template> -->

              <template v-slot:[`item.input_datetime`]="{ item }">
                {{ item.input_datetime | datetime }}
              </template>

              <template v-slot:[`footer.page-text`]="props">
                Menampilkan {{ props.pageStart }} - {{ props.pageStop }} data
                dari total {{ props.itemsLength }} data
                <v-btn
                  text
                  class="ml-4 text-none"
                  small
                  @click="
                    selected.voucher = [];
                    fetchData();
                  "
                  ><v-icon left>mdi-sync</v-icon> Perbarui</v-btn
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- FORM -->
    <v-dialog
      v-model="dialog.voucher"
      v-if="dialog.voucher"
      persistent
      no-click-animation
      max-width="450"
      transition="slide-x-reverse-transition"
      content-class="my-custom-dialog"
      scrollable
    >
      <v-card tile>
        <v-card-title class="subtitle-1 px-4 pt-4" style="color: #4ab762"
          >{{ form.id > 0 ? "Ubah" : "Tambah" }} Voucher</v-card-title
        >
        <v-card-subtitle class="caption grey--text text--darken-1 px-4 py-1"
          >Lengkapi form dibawah ini</v-card-subtitle
        >
        <v-divider></v-divider>
        <v-card-text style="height: calc(100vh - 120px)" class="py-4 px-0">
          <v-form
            ref="form-voucher"
            v-model="valid"
            lazy-validation
            @keyup.native.enter="() => {}"
          >
            <div class="px-4">
              <ImageUploadLandscape v-model="form.image_landscape_url" class="mb-4" />
              
              <ImageUpload v-model="form.image_square_url" class="mb-4" />

              <div class="subtitle-2">
                Nama Voucher
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <v-text-field
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.name"
                :error-messages="validation.name"
                @keyup="validation.name = ''"
                :rules="[
                  (v) => !!v || `Nama voucher tidak boleh kosong`,
                  (v) =>
                    (v && v.length <= 50) || 'Nama voucher maksimal 50 karakter',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2 mt-4">
                Kode Voucher
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <v-text-field
                solo
                autocomplete="off"
                color="#4ab762"
                type="text"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.code"
                :error-messages="validation.code"
                @keyup="validation.code = ''"
                :rules="[
                  (v) => !!v || `Kode voucher tidak boleh kosong`,
                  (v) =>
                    (v && v.length <= 50) || 'Kode voucher maksimal 50 karakter',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2 mt-4">
                Syarat Voucher
                <small style="color: #e74c3c"><i> *wajib diisi</i></small>
              </div>
              <!-- <v-autocomplete
                dense
                :items="itemTypeData"
                color="#4ab762"
                v-model="form.item_type"
                :disabled="loading"
                solo
                item-text="label"
                item-value="value"
                class="rounded-lg mb-3"
                :error-messages="validation.item_type"
                @keyup="validation.item_type = ''"
                @keyup.delete="deleteType"
                placeholder="Pilih disini"
              >
              </v-autocomplete> -->

              <v-list-item
                class="py-0 rounded-lg"
                style="background-color: #f9f9f9; border: 1px solid #ebebeb"
                @click="dialog.itemType = true"
              >
                <v-list-item-avatar
                  class="py-0 rounded-lg"
                  tile
                  color="rgba(74, 183, 98, 0.7)"
                >
                  <v-icon color="#fff">mdi-tag-multiple</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 grey--text text--darken-1"
                  >
                    {{ computedSyarat.title }}</v-list-item-title
                  >
                  <v-list-item-subtitle
                    class="caption grey--text text--darken-1"
                    >{{ computedSyarat.subtitle }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="#7f8c8d">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <div class="subtitle-2 mt-4">
                Pilih Platform
                <small style="color: #e74c3c"><i> *wajib diisi</i></small>
              </div>
              <v-autocomplete
                dense
                :items="platformData"
                color="#4ab762"
                v-model="form.platform"
                :disabled="loading"
                solo
                item-text="label"
                item-value="value"
                class="rounded-lg mb-3"
                :error-messages="validation.platform"
                @keyup="validation.platform = ''"
                @keyup.delete="deleteType"
                placeholder="Pilih disini"
              >
              </v-autocomplete>

              <div class="subtitle-2 mt-4">
                Diskon <small style="color: #f39c12"><i>(dalam %)</i></small>
                <small style="color: #e74c3c"
                  ><i> *wajib diisi minimal 0</i></small
                >
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="number"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.discount_percent"
                :error-messages="validation.discount_percent"
                @keyup="validation.discount_percent = ''"
                :rules="[
                  (v) =>
                    (v != null && v != '' && v >= 0 && v <= 100) ||
                    'Diskon harus antara 0 sampai 100',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2 mt-4">
                Limit Penggunaan Per Buyer <small style="color: #f39c12"><i>(isi 0 jika tidak ada limit)</i></small>
                <small style="color: #e74c3c"
                  ><i> *wajib diisi minimal 0</i></small
                >
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="number"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.user_limit"
                :error-messages="validation.user_limit"
                @keyup="validation.user_limit = ''"
                :rules="[
                  (v) =>
                    (v != null && v != '' && v >= 0 ) ||
                    'Diskon harus minimal 0',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2 mt-4">
                Jumlah Voucher <small style="color: #f39c12"><i>(isi 0 jika tidak ada limit)</i></small>
                <small style="color: #e74c3c"
                  ><i> *wajib diisi minimal 0</i></small
                >
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="number"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                v-model="form.quota"
                :error-messages="validation.quota"
                @keyup="validation.quota = ''"
                :rules="[
                  (v) =>
                    (v != null && v != '' && v >= 0 ) ||
                    'Diskon harus minimal 0',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2 mt-4">
                Minimal Transaksi <small style="color: #f39c12"><i>(isi 0 jika tidak ada minimal)</i></small>
                <small style="color: #e74c3c"
                  ><i> *wajib diisi minimal 0</i></small
                >
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="number"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                prefix="Rp"
                v-model="form.min_order"
                :error-messages="validation.min_order"
                @keyup="validation.min_order = ''"
                :rules="[
                  (v) =>
                    (v != null && v != '' && v >= 0 ) ||
                    'Diskon harus minimal 0',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <div class="subtitle-2 mt-4">
                Maksimal Potongan <small style="color: #f39c12"><i>(isi 0 jika tidak ada maksimal)</i></small>
                <small style="color: #e74c3c"
                  ><i> *wajib diisi minimal 0</i></small
                >
              </div>
              <v-text-field
                dense
                solo
                autocomplete="off"
                color="#4ab762"
                type="number"
                placeholder="Tulis disini"
                class="rounded-lg mb-3"
                :disabled="loading"
                prefix="Rp"
                v-model="form.discount_max"
                :error-messages="validation.discount_max"
                @keyup="validation.discount_max = ''"
                :rules="[
                  (v) =>
                    (v != null && v != '' && v >= 0 ) ||
                    'Diskon harus minimal 0',
                ]"
              >
                <template v-slot:message="{ message }">
                  <v-tooltip top max-width="250" color="#e74c3c">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="22"
                        class="mr-2 ml-n2"
                        color="#e74c3c"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </template>
                    <span>{{ message }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </div>

            <div
              style="background: rgba(0, 0, 0, 0.1)"
              class="mb-3 mt-6 px-4 py-2 subtitle-2"
            >
              PERIODE VOUCHER
            </div>

            <div class="px-4">
              <div class="subtitle-2">
                Pilih Tanggal dan Jam Mulai Voucher
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <div>
                <v-dialog
                  ref="dateStart"
                  v-model="dialog.dateStart"
                  :return-value.sync="form.start_date"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      placeholder="Pilih disini"
                      class="rounded-lg mb-3"
                      :disabled="loading"
                      v-model="computedStartDate"
                      :error-messages="validation.start_date"
                      @change="validation.start_date = ''"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 60%; display: inline-block"
                    >
                      <template v-slot:message="{ message }">
                        <v-tooltip top max-width="250" color="#e74c3c">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              size="22"
                              class="mr-2 ml-n2"
                              color="#e74c3c"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{ message }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="form.start_date"
                    class="rounded-lg"
                    color="#4ab762"
                  >
                    <div
                      class="d-flex justify-space-between"
                      style="width: 100%"
                    >
                      <v-btn
                        rounded
                        depressed
                        class="
                          text-none
                          white--text
                          text--lighten-3
                          flex-grow-1
                        "
                        color="#4ab762"
                        @click="$refs.dateStart.save(form.start_date)"
                      >
                        <v-icon left>mdi-check-circle-outline</v-icon>
                        Terapkan
                      </v-btn>
                      <v-btn
                        rounded
                        depressed
                        outlined
                        class="text-none ml-2"
                        color="#e74c3c"
                        width="80"
                        @click="dialog.dateStart = false"
                      >
                        Batal
                      </v-btn>
                    </div>
                  </v-date-picker>
                </v-dialog>
                <v-dialog
                  ref="timeStart"
                  v-model="dialog.timeStart"
                  :return-value.sync="form.start_time"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      placeholder="Pilih disini"
                      class="rounded-lg mb-3 ml-4"
                      :disabled="loading"
                      v-model="form.start_time"
                      :error-messages="validation.start_time"
                      @change="validation.start_time = ''"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 35%; display: inline-block"
                    >
                      <template v-slot:message="{ message }">
                        <v-tooltip top max-width="250" color="#e74c3c">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              size="22"
                              class="mr-2 ml-n2"
                              color="#e74c3c"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{ message }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-time-picker
                    no-title
                    format="24hr"
                    v-if="dialog.timeStart"
                    v-model="form.start_time"
                    class="rounded-lg"
                    color="#4ab762"
                  >
                    <div
                      class="d-flex justify-space-between"
                      style="width: 100%"
                    >
                      <v-btn
                        rounded
                        depressed
                        class="
                          text-none
                          white--text
                          text--lighten-3
                          flex-grow-1
                        "
                        color="#4ab762"
                        @click="$refs.timeStart.save(form.start_time)"
                      >
                        <v-icon left>mdi-check-circle-outline</v-icon>
                        Terapkan
                      </v-btn>
                      <v-btn
                        rounded
                        depressed
                        outlined
                        class="text-none ml-2"
                        color="#e74c3c"
                        width="80"
                        @click="dialog.timeStart = false"
                      >
                        Batal
                      </v-btn>
                    </div>
                  </v-time-picker>
                </v-dialog>
              </div>

              <div class="subtitle-2">
                Pilih Tanggal dan Jam Berakhir Voucher
                <small style="color: #e74c3c"><i>*wajib diisi</i></small>
              </div>
              <div>
                <v-dialog
                  ref="dateEnd"
                  v-model="dialog.dateEnd"
                  :return-value.sync="form.end_date"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      placeholder="Pilih disini"
                      class="rounded-lg mb-3"
                      :disabled="loading"
                      v-model="computedEndDate"
                      :error-messages="validation.end_date"
                      @change="validation.end_date = ''"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 60%; display: inline-block"
                    >
                      <template v-slot:message="{ message }">
                        <v-tooltip top max-width="250" color="#e74c3c">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              size="22"
                              class="mr-2 ml-n2"
                              color="#e74c3c"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{ message }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="form.end_date"
                    :min="form.start_date"
                    class="rounded-lg"
                    color="#4ab762"
                  >
                    <div
                      class="d-flex justify-space-between"
                      style="width: 100%"
                    >
                      <v-btn
                        rounded
                        depressed
                        class="
                          text-none
                          white--text
                          text--lighten-3
                          flex-grow-1
                        "
                        color="#4ab762"
                        @click="$refs.dateEnd.save(form.end_date)"
                      >
                        <v-icon left>mdi-check-circle-outline</v-icon>
                        Terapkan
                      </v-btn>
                      <v-btn
                        rounded
                        depressed
                        outlined
                        class="text-none ml-2"
                        color="#e74c3c"
                        width="80"
                        @click="dialog.dateEnd = false"
                      >
                        Batal
                      </v-btn>
                    </div>
                  </v-date-picker>
                </v-dialog>
                <v-dialog
                  ref="timeEnd"
                  v-model="dialog.timeEnd"
                  :return-value.sync="form.end_time"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      placeholder="Pilih disini"
                      class="rounded-lg mb-3 ml-4"
                      :disabled="loading"
                      v-model="form.end_time"
                      :error-messages="validation.end_time"
                      @change="validation.end_time = ''"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 35%; display: inline-block"
                    >
                      <template v-slot:message="{ message }">
                        <v-tooltip top max-width="250" color="#e74c3c">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              size="22"
                              class="mr-2 ml-n2"
                              color="#e74c3c"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>{{ message }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-time-picker
                    no-title
                    format="24hr"
                    v-if="dialog.timeEnd"
                    v-model="form.end_time"
                    class="rounded-lg"
                    color="#4ab762"
                  >
                    <div
                      class="d-flex justify-space-between"
                      style="width: 100%"
                    >
                      <v-btn
                        rounded
                        depressed
                        class="
                          text-none
                          white--text
                          text--lighten-3
                          flex-grow-1
                        "
                        color="#4ab762"
                        @click="$refs.timeEnd.save(form.end_time)"
                      >
                        <v-icon left>mdi-check-circle-outline</v-icon>
                        Terapkan
                      </v-btn>
                      <v-btn
                        rounded
                        depressed
                        outlined
                        class="text-none ml-2"
                        color="#e74c3c"
                        width="80"
                        @click="dialog.timeEnd = false"
                      >
                        Batal
                      </v-btn>
                    </div>
                  </v-time-picker>
                </v-dialog>
              </div>
            </div>

          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            class="text-none white--text text--lighten-3 flex-grow-1"
            color="#4ab762"
            @click="doSave"
          >
            <v-icon left>mdi-check-circle-outline</v-icon
            >{{ form.id > 0 ? "Simpan" : "Tambahkan" }} Voucher
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            width="130"
            @click="
              dialog.voucher = false;
              formReset();
              $refs['form-voucher'].resetValidation();
              validationReset();
            "
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.itemType"
      v-if="dialog.itemType"
      persistent
      no-click-animation
      max-width="450"
      transition="slide-x-reverse-transition"
      content-class="my-custom-dialog"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Pilih Syarat Voucher
          <div class="caption grey--text text--darken-1">
            Atur syarat voucher pada form dibawah ini
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: calc(100vh - 118px)" class="py-4 px-0">
          <div class="px-4">
            <div class="subtitle-2">
              Gunakan voucher untuk
              <small style="color: #e74c3c"><i>*wajib diisi</i></small>
            </div>
            <v-radio-group
              v-model="formItemType.item_type"
              row
              dense
              hide-details
              class="mt-0 mb-3"
              @change="
              formItemType.page = 1;
              formItemType.totalPage = 1;
              formItemType.data = [];
              formItemType.items = [];
              formItemType.item_type === 'course' ? formItemType.item_for = 'all' : ''
              "
            >
              <v-radio
                label="Kategori"
                value="category"
                color="#4ab762"
              ></v-radio>
              <v-radio
                label="Kelas"
                value="course"
                color="#4ab762"
              ></v-radio>
              <!-- <v-radio
                label="Publisher"
                value="publisher"
                color="#4ab762"
              ></v-radio> -->
            </v-radio-group>

            <div class="subtitle-2 mt-4">
              Berlaku pada
              <small style="color: #e74c3c"><i>*wajib diisi</i></small>
            </div>
            <v-radio-group
              v-model="formItemType.item_for"
              row
              dense
              hide-details
              @change="
              formItemType.item_for === 'all' ? formItemType.items = [] : ''
              "
              class="mt-0 mb-3"
            >
              <v-radio label="Semua" value="all" color="#4ab762"></v-radio>
              <v-radio
                label="Pilih beberapa"
                value="certain"
                v-if="formItemType.item_type === 'category'"
                color="#4ab762"
              ></v-radio>
            </v-radio-group>
          </div>

          <div
            style="background: rgba(0, 0, 0, 0.1)"
            class="mb-3 mt-4 px-4 py-2 subtitle-2"
          >
            DATA TERPILIH
          </div>

          <div v-if="formItemType.item_for != 'all'">
            <div class="text-right px-4 mt-4">
              <v-btn
                text
                x-small
                class="px-0 text-none"
                color="#3498db"
                @click="
                dialog.items = true;
                fetchTypeVoucher();
                "
                ><v-icon left>mdi-plus</v-icon>Tambahkan Data</v-btn
              >
            </div>

            <v-list dense class="pb-0" v-if="formItemType.items.length > 0">
              <div v-for="(item, key) in formItemType.items" :key="key">
                <v-divider></v-divider>
                <v-list-item class="py-0">
                  <v-list-item-avatar
                    class="py-0 rounded-lg"
                    tile
                    color="#ecf0f1"
                  >
                    <span v-if="!item.img_url" style="font-size: 14px">{{
                      item.name | initial
                    }}</span>
                    <v-img v-else :src="item.img_url"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title
                      class="subtitle-2 grey--text text--darken-1"
                      >{{ item.name | emptyData }}</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn small icon color="#e74c3c" @click.stop="
                    formItemType.items.splice(key, 1);
                    ">
                      <v-icon small color="#e74c3c">mdi-delete-forever</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list>
            <div v-else class="caption text-center mt-4">
              Belum ada data. <br />
              Silahkan klik tombol tambah data diatas.
            </div>
          </div>
          <div v-else class="caption text-left px-4 mt-4">
            Semua
            <span v-if="formItemType.item_type == 'category'">kategori</span>
            <span v-if="formItemType.item_type == 'organization'"
              >organisasi</span
            >
            <span v-if="formItemType.item_type == 'publisher'">publisher</span>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            class="text-none white--text text--lighten-3 flex-grow-1"
            color="#4ab762"
            @click="insertSyarat(); dialog.itemType = false;"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>Terapkan Syarat Voucher
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            width="130"
            @click="dialog.itemType = false; 
                formItemType.items = form.item_array;
                formItemType.item_type = form.item_type;
                formItemType.item_for = form.item_array.length > 0 ? 'certain' : 'all'"
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.items"
      v-if="dialog.items"
      persistent
      no-click-animation
      max-width="450"
      transition="slide-x-reverse-transition"
      content-class="my-custom-dialog"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Pilih
          <span v-if="formItemType.item_type == 'category'">Kategori</span>
          <span v-if="formItemType.item_type == 'organization'"
            >Organisasi</span
          >
          <span v-if="formItemType.item_type == 'publisher'">Publisher</span>
          <div class="caption grey--text text--darken-1">
            Pilih pada list dibawah ini
          </div>
        </v-card-title>
        <v-divider></v-divider>

        <v-text-field
          dense
          solo
          autocomplete="off"
          color="#4ab762"
          type="text"
          clearable
          clear-icon="mdi-backspace"
          placeholder="Cari berdasarkan nama"
          prepend-inner-icon="mdi-account-search-outline"
          class="rounded-lg mt-4 px-4"
          :disabled="loading"
          v-model="formItemType.search"
        ></v-text-field>

        <div class="text-right px-4 mt-4 caption">
          Data Terpilih
          <b
            >({{
              getChooseData.length == 0
                ? "belum ada"
                : getChooseData.length
            }})</b
          >
        </div>

        <v-list dense class="pb-0">
          <virtual-list
            style="height: calc(100vh - 216px); overflow-y: auto"
            :data-key="'id'"
            :data-sources="formItemType.data"
            :data-component="formItemType.itemTypePromo"
            :extra-props="{ showCheckbox: true }"
          >
            <div slot="footer" class="text-center my-2">
              <v-chip
                color="white"
                text-color="green"
                v-show="formItemType.loading"
              >
                <v-progress-circular
                  indeterminate
                  size="16"
                  width="2"
                  class="mr-4"
                  color="green"
                ></v-progress-circular>
                Memuat data...
              </v-chip>
              <v-btn
                v-show="
                  !formItemType.loading &&
                  formItemType.page != formItemType.totalPage &&
                  formItemType.totalPage != 0
                "
                @click="onScrollToBottom()"
                depressed
                small
                class="text-none white--text"
                color="green"
                >Tampilkan lagi
                <v-icon right small>mdi-arrow-down</v-icon></v-btn
              >
            </div>
            <div
              slot="footer"
              class="caption text-center"
              v-show="formItemType.data.length <= 0 && !formItemType.loading"
            >
              Tidak ada data.
            </div>
          </virtual-list>
        </v-list>

        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            class="text-none white--text text--lighten-3 flex-grow-1"
            color="#4ab762"
            @click="insertList();"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>Masukkan ke list
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            width="130"
            @click="dialog.items = false"
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.detail"
      v-if="dialog.detail"
      max-width="450"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Detail Voucher
          <div class="caption grey--text text--darken-1">
            Informasi Voucher Diskon
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          style="height: calc(100vh - 118px)"
          class="py-4 px-4">
          <v-list dense class="mt-0">
            <div
              class="
                subtitle-2
                font-weight-bold
                grey--text
                mb-5
                text--darken-1 text-center text-decoration-underline
              "
            >
              Informasi Voucher
            </div>
            <div class="text-center">
              <v-chip
                color="white"
                text-color="green"
                v-show="loading_detail"
              >
                <v-progress-circular
                  indeterminate
                  size="16"
                  width="2"
                  class="mr-4"
                  color="green"
                ></v-progress-circular>
                Memuat data...
              </v-chip>
            </div>
            <div v-if="!loading_detail && Object.keys(detail).length > 0">
              <div>
                <v-img
                  :src="detail.image_landscape_url"
                  width="100%"
                  height="180"
                  style="border-radius: 12px;">
                </v-img>
              </div>
              <div class="my-4">
                <v-img
                  :src="detail.image_portrait_url"
                  width="93"
                  height="93"
                  class="mx-auto"
                  style="border-radius: 12px;">
                </v-img>
              </div>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >Nama Voucher</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption grey--text text--darken-1"
                    style="white-space: normal"
                    >{{ detail.name | emptyData }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >Diskon</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption grey--text text--darken-1"
                  >
                    <v-chip
                      small
                      class="my-2 font-weight-bold"
                      label
                      style="
                        color: rgb(52 152 219);
                        background-color: rgb(52 152 219 / 10%);
                      "
                    >
                      <v-icon small left color="rgb(52 152 219)">mdi-sale</v-icon
                      >{{ detail.discount_percent + "%" }}
                    </v-chip></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >Kode Voucher</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption grey--text text--darken-1"
                    style="white-space: normal"
                    >{{ detail.code | emptyData }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >Kuota</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption green--text text--darken-1"
                    style="white-space: normal"
                    >{{ parseInt(detail.quota) === 0 ? 'Unlimited' : detail.quota | emptyData }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >Kuota Terpakai</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption red--text text--darken-1"
                    style="white-space: normal"
                    >{{ detail.quota_used | emptyData }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >Kuota Sisa</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption primary--text text--darken-1"
                    style="white-space: normal"
                    >{{ parseInt(detail.quota_balance) === 0 ? 'Unlimited' : detail.quota_balance | emptyData }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >Minimal Transaksi</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption grey--text text--darken-1"
                    style="white-space: normal">
                    <span v-if="parseInt(detail.min_order) === 0 ">
                      Tidak ada minimal
                    </span>
                    <span v-if="parseInt(detail.min_order) !== 0 ">
                      {{ detail.min_order | price }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >Maksimal Potongan</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption grey--text text--darken-1"
                    style="white-space: normal"
                    >
                    <span v-if="parseInt(detail.discount_max) === 0 ">
                      Tidak ada maksimal
                    </span>
                    <span v-if="parseInt(detail.discount_max) !== 0 ">
                      {{ detail.discount_max | price }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <div
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >Limit Penggunaan Peruser</div
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption primary--text text--darken-1"
                    style="white-space: normal"
                    >{{ parseInt(detail.user_limit) === 0 ? 'Unlimited' : detail.user_limit | emptyData }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >Status Voucher</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption grey--text text--darken-1"
                  >
                    <v-chip
                      small
                      class="my-2 font-weight-bold"
                      label
                      style="
                        color: rgb(46 204 113);
                        background-color: rgb(46 204 113 / 10%);
                      "
                      v-if="detail.active_bool"
                    >
                      Aktif
                    </v-chip>
                    <v-chip
                      small
                      class="my-2 font-weight-bold"
                      label
                      style="
                        color: rgb(231, 76, 60);
                        background-color: rgb(231 76 60 / 10%);
                      "
                      v-else
                    >
                      Tidak Aktif
                    </v-chip></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >TGL MULAI VOUCHER</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption grey--text text--darken-1"
                    >{{
                      detail.start_datetime | dateFull
                    }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >TGL BERAKHIR VOUCHER</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption grey--text text--darken-1"
                    >{{
                      detail.end_datetime | dateFull
                    }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >BERLAKU UNTUK</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption grey--text text--darken-1"
                  >
                    <v-chip
                      small
                      class="my-2 font-weight-bold"
                      label
                      style="
                        color: rgb(241 196 15);
                        background-color: rgb(241 196 15 / 10%);
                      "
                      v-if="detail.item_type == 'all category'"
                    >
                      Semua Kategori
                    </v-chip>
                    <v-chip
                      small
                      class="my-2 font-weight-bold"
                      label
                      style="
                        color: rgb(46 204 113);
                        background-color: rgb(46 204 113 / 10%);
                      "
                      v-if="detail.item_type == 'category'"
                    >
                      Kategori Tertentu
                    </v-chip>
                    <v-chip
                      small
                      class="my-2 font-weight-bold"
                      label
                      style="
                        color: rgb(52 152 219);
                        background-color: rgb(52 152 219 / 10%);
                      "
                      v-if="detail.item_type == 'all course'"
                    >
                      Semua Kelas
                    </v-chip></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >PLATFORM</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption grey--text text--darken-1"
                  >
                    <v-chip
                      small
                      class="my-2 font-weight-bold"
                      label
                      style="
                        color: rgb(241 196 15);
                        background-color: rgb(241 196 15 / 10%);
                      "
                      v-if="detail.platform == 'mobile'"
                    >
                      Aplikasi Mobile
                    </v-chip>
                    <v-chip
                      small
                      class="my-2 font-weight-bold"
                      label
                      style="
                        color: rgb(52 152 219);
                        background-color: rgb(52 152 219 / 10%);
                      "
                      v-if="detail.platform == 'web'"
                    >
                      Aplikasi Web
                    </v-chip>
                    <v-chip
                      small
                      class="my-2 font-weight-bold"
                      label
                      style="
                        color: rgb(149 165 166);
                        background-color: rgb(149 165 166 / 10%);
                      "
                      v-if="detail.platform == 'semua'"
                    >
                      Semua Aplikasi
                    </v-chip></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >TGL BUAT</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption grey--text text--darken-1"
                    >{{
                      detail.input_datetime | datetime
                    }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="py-0">
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 text-uppercase grey--text text--darken-1"
                    >TERAKHIR DIUBAH</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="text-right caption grey--text text--darken-1"
                    >{{
                      detail.update_datetime | datetime
                    }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <div v-if="detail.items.length > 0">
                <v-list-item class="py-0">
                  <v-list-item-content class="py-0">
                    <v-list-item-title
                      class="subtitle-2 text-uppercase grey--text text--darken-1"
                      >DATA KATEGORI: </v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="py-0" v-for="(source, i) in detail.items" :key="i">
                  <v-list-item-avatar class="py-0 rounded-lg" tile color="#ecf0f1">
                    <span v-if="!source.img_url" style="font-size: 14px">{{
                      source.name | initial
                    }}</span>
                    <v-img v-else :src="source.img_url"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="subtitle-2 grey--text text--darken-1">{{
                      source.name | emptyData
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </div>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="dialog.detail = false"
            class="text-none flex-grow-1"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.confirmDelete"
      persistent
      no-click-animation
      max-width="340"
      content-class="rounded-lg"
    >
      <v-card class="rounded-lg">
        <v-card-title
          class="text-h6 font-weight-bold grey--text text--darken-2"
        >
          <v-icon left>mdi-information</v-icon>Konfirmasi Hapus
        </v-card-title>
        <v-card-text style="min-height: 80px"
          >Apakah yakin akan menghapus voucher <b>{{ form.name }}</b
          >?
        </v-card-text>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#4ab762"
            depressed
            rounded
            @click="doDelete"
            class="text-none white--text text--lighten-3 flex-grow-1"
          >
            <v-icon left>mdi-check-circle-outline</v-icon>
            Ya, hapus
          </v-btn>
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="dialog.confirmDelete = false"
            class="text-none"
            width="100"
          >
            Batal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import "moment/locale/id";

import VirtualList from "vue-virtual-scroll-list";
import itemTypePromo from "@/components/ItemTypePromo";
export default {
  name: "Voucher",

  components: {
    ImageUpload: () =>
      import(/* webpackChunkName: "ImageUpload" */ "@/components/ImageUpload"),
    ImageUploadLandscape: () =>
      import(/* webpackChunkName: "ImageUpload" */ "@/components/ImageUploadLandscape"),
  },

  data: () => ({
    breadcrumbs: [
      {
        text: "Dashboard",
        disabled: false,
        href: "/",
      },
      {
        text: "Voucher",
        disabled: true,
        href: "/voucher",
      },
    ],

    loading: false,
    loading_detail: false,
    dialog: {
      voucher: false,
      detail: false,
      confirmDelete: false,

      dateStart: false,
      timeStart: false,
      dateEnd: false,
      timeEnd: false,

      itemType: false,
      items: false,
    },
    selected: {
      voucher: [],
    },
    detail: {},
    headers: {
      voucher: [
        {
          text: "KODE VOUCHER",
          width: "180",
          sortable: true,
          align: "start",
          value: "code",
        },
        {
          text: "NAMA VOUCHER",
          width: "300",
          sortable: true,
          align: "start",
          value: "name",
        },
        {
          text: "BERLAKU UNTUK",
          width: "300",
          sortable: true,
          align: "center",
          value: "item_type",
        },
        {
          text: "KUOTA",
          width: "180",
          sortable: true,
          align: "center",
          value: "quota",
        },
        // {
        //   text: "KUOTA TERPAKAI",
        //   width: "180",
        //   sortable: true,
        //   align: "center",
        //   value: "quota_used",
        // },
        // {
        //   text: "KUOTA TERSEDIA",
        //   width: "180",
        //   sortable: true,
        //   align: "center",
        //   value: "quota_balance",
        // },
        {
          text: "LIMIT PENGGUNAAN",
          width: "240",
          sortable: true,
          align: "center",
          value: "user_limit",
        },
        {
          text: "DISKON",
          width: "180",
          sortable: true,
          align: "center",
          value: "discount_percent",
        },
        {
          text: "MAKS. DISKON",
          width: "180",
          sortable: true,
          align: "center",
          value: "discount_max",
        },
        {
          text: "MIN. TRANSAKSI",
          width: "180",
          sortable: true,
          align: "center",
          value: "min_order",
        },
        {
          text: "TGL MULAI",
          width: "180",
          sortable: true,
          align: "center",
          value: "start_datetime",
        },
        {
          text: "TGL SELESAI",
          width: "180",
          sortable: true,
          align: "center",
          value: "end_datetime",
        },
        {
          text: "PLATFORM",
          width: "120",
          sortable: true,
          align: "center",
          value: "platform",
        },
        {
          text: "STATUS AKTIF",
          width: "180",
          sortable: true,
          align: "center",
          value: "active_bool",
        },
        {
          text: "TGL. BUAT",
          width: "180",
          sortable: true,
          align: "center",
          value: "input_datetime",
        },
        {
          text: "NAMA PEMBUAT",
          width: "180",
          sortable: true,
          align: "start",
          value: "user_name",
        },
        // {
        //   text: "ROLE PEMBUAT",
        //   width: "180",
        //   sortable: true,
        //   align: "center",
        //   value: "user_role_name",
        // },
      ],
    },
    dataGrid: {
      voucher: [],
    },
    options: {
      voucher: {},
    },
    totalData: {
      voucher: 0,
    },
    footerProps: {
      "show-current-page": true,
      "show-first-last-page": true,
      "items-per-page-options": [10, 15, 30, 50, 100],
      "items-per-page-text": "Data per halaman",
      "page-text": "{0} - {1} dari total {2}",
    },
    platformData: [
      {
        value: "semua",
        label: "Semua Platform",
      },
      {
        value: "web",
        label: "Web",
      },
      {
        value: "mobile",
        label: "Mobile",
      },
    ],

    formItemType: {
      search: "",
      searchTimeout: null,
      loading: false,
      page: 1,
      totalPage: 1,
      data: [],

      item_type: "category",
      item_for: "all",
      items: [],

      itemTypePromo,
    },

    valid: true,
    form: {
      id: "",
      code: "",
      name: "",
      image_square_url: "",
      image_landscape_url: "",
      image_portrait_url: "",
      item_type: "category",
      item_array: [],
      platform: "semua",
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",
      quota: "",
      user_limit: "",
      discount_percent: "",
      discount_max: "",
      min_order: "",
      active_bool: true,
    },
    validation: {
      id: "",
      code: "",
      name: "",
      image_square_url: "",
      image_landscape_url: "",
      image_portrait_url: "",
      item_type: "",
      item_array: [],
      platform: "",
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",
      quota: "",
      user_limit: "",
      discount_percent: "",
      discount_max: "",
      min_order: "",
      active_bool: "",
    },
    typeData: [
      {
        value: "all category",
        label: "Semua Kategori",
      },
      {
        value: "all course",
        label: "Semua Kelas",
      },
      {
        value: "all organization",
        label: "Semua Organisasi",
      },
      {
        value: "category",
        label: "Kategori Tertentu",
      },
      {
        value: "course",
        label: "Kelas Tertentu",
      },
      {
        value: "organization",
        label: "Organisasi Tertentu",
      },
    ],
  }),

  watch: {
    "options.voucher": {
      async handler() {
        this.selected.voucher = [];
        await this.fetchData();
      },
      deep: true,
    },

    "formItemType.search": {
      handler() {
        if (this.formItemType.searchTimeout)
          clearTimeout(this.formItemType.searchTimeout);
        this.formItemType.searchTimeout = setTimeout(() => {
          this.formItemType.page = 1;
          this.formItemType.totalPage = 1;
          this.fetchTypePromo();
        }, 800);
      },
    },
  },

  computed: {
    computedStartDate() {
      return this.form.start_date
        ? moment(this.form.start_date).format("DD MMMM YYYY")
        : "";
    },
    computedEndDate() {
      return this.form.end_date
        ? moment(this.form.end_date).format("DD MMMM YYYY")
        : "";
    },
    computedStartApplyDate() {
      return this.form.start_apply_date
        ? moment(this.form.start_apply_date).format("DD MMMM YYYY")
        : "";
    },
    computedEndApplyDate() {
      return this.form.end_apply_date
        ? moment(this.form.end_apply_date).format("DD MMMM YYYY")
        : "";
    },
    computedSyarat() {
      let title = this.form.item_array.length == 0 ? "Semua " : "";
      let subtitle =
        "Dapat digunakan " +
        (this.form.item_array.length == 0
          ? "semua "
          : "(" + this.form.item_array.length + ")");
      if (this.form.item_type == "category") {
        title += "Kategori Kelas";
        subtitle += "kategori kelas";
      }
      if (this.form.item_type == "course") {
        title += "Kelas";
        subtitle += "kelas";
      }
      if (this.form.item_type == "publisher") {
        title += "Publisher";
        subtitle += "publisher";
      }

      title += this.form.item_array.length > 0 ? " tertentu" : "";

      return {
        title,
        subtitle,
      };
    },
    getChooseData() {
      return this.formItemType.data.filter((v) => {
        return v.selected;
      });
    },
  },

  methods: {
    formReset() {
      Object.assign(this.form, {
        id: "",
        code: "",
        name: "",
        image_square_url: "",
        image_landscape_url: "",
        image_portrait_url: "",
        item_type: "category",
        item_array: [],
        platform: "semua",
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
        quota: "",
        user_limit: "",
        discount_percent: "",
        discount_max: "",
        min_order: "",
        active_bool: true,
      });
    },
    validationReset() {
      Object.assign(this.validation, {
        id: "",
        code: "",
        name: "",
        image_square_url: "",
        image_landscape_url: "",
        image_portrait_url: "",
        item_type: "",
        item_array: [],
        platform: "",
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
        quota: "",
        user_limit: "",
        discount_percent: "",
        discount_max: "",
        min_order: "",
        active_bool: "",
      });
    },
    setForm() {
      let selected = this.selected.voucher[0];
      Object.assign(this.form, {
        id: selected.id,
        code: selected.code,
        name: selected.name,
        image_square_url: selected.image_square_url,
        image_landscape_url: selected.image_landscape_url,
        image_portrait_url: selected.image_portrait_url,
        item_type: selected.item_type === 'all category' ? 'category' : selected.item_type === 'all course' ? 'course' : selected.item_type,
        item_array: selected.item_array,
        platform: selected.platform,
        start_date: moment(selected.start_datetime).format("YYYY-MM-DD"),
        start_time: moment(selected.start_datetime).format("HH:mm"),
        end_date: moment(selected.end_datetime).format("YYYY-MM-DD"),
        end_time: moment(selected.end_datetime).format("HH:mm"),
        quota: selected.quota,
        user_limit: selected.user_limit,
        discount_percent: selected.discount_percent,
        discount_max: selected.discount_max,
        min_order: selected.min_order,
        active_bool: true,
      });
      this.fetchTypePromoEdit();
    },

    async fetchTypePromoEdit(){
      this.formItemType.item_type = this.form.item_type;
      this.formItemType.item_for = this.form.item_array.length > 0 ? 'certain' : 'all'
      this.formItemType.data = []
      let params = {
        limit: 1000000000
      };

      this.formItemType.loading = true;

      let url = "";

      switch (this.formItemType.item_type) {
        case 'category':
          url = "admin/course_category";
          break;
        case 'organization':
          url = "organization";
          break;
        case 'publisher':
          url = "admin/user";
          break;
      
        default:
          url = "admin/course_category";
          break;
      }

      let response = await this.$get(url, params);

      this.formItemType.loading = false;

      if (response.status == 200) {
        this.formItemType.items = []
        for (let index = 0; index < response.results.data.length; index++) {
          for (let j = 0; j < this.form.item_array.length; j++) {
            // console.log(response.results.data[index].id === this.form.item_array[j])
            if (response.results.data[index].id === this.form.item_array[j]) {
              this.formItemType.items.push(response.results.data[index])
            }
          }
        }
        this.form.item_array = this.formItemType.items
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async fetchTypePromo(){
      this.formItemType.data = []
      let params = {
        page: this.formItemType.page,
        limit: 10,
        'id[nin]':this.formItemType.items.map(e => e.id).join(",")
      };

      if (
        this.formItemType.search &&
        this.formItemType.search.length > 0
      ) {
        params["name[lse]"] = this.formItemType.search;
      }

      this.formItemType.loading = true;

      let url = "";

      switch (this.formItemType.item_type) {
        case 'category':
          url = "admin/course_category";
          break;
        case 'organization':
          url = "organization";
          break;
        case 'publisher':
          url = "admin/user";
          break;
      
        default:
          url = "admin/course_category";
          break;
      }

      let response = await this.$get(url, params);

      this.formItemType.loading = false;

      if (response.status == 200) {
        if (this.formItemType.page <= 1) {
          this.formItemType.data = response.results.data;
        } else {
          this.formItemType.data =
            this.formItemType.data.concat(response.results.data);
        }
        this.formItemType.totalPage = parseInt(
          response.results.pagination.total_page
        );
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async onScrollToBottom() {
      if (this.formItemType.loading) {
        return;
      }

      if (this.formItemType.page + 1 <= this.formItemType.totalPage) {
        this.formItemType.page++;
        this.fetchTypePromo();
      }
    },

    insertList(){
      let selected = this.getChooseData.map((item) => item)

      this.formItemType.items.push(...selected);

      this.dialog.items = false;
    },

    insertSyarat(){
      this.form.item_array = []
      let selected = this.formItemType.items.map((item) => item)
      this.form.item_type = this.formItemType.item_type

      this.form.item_array.push(...selected);
    },

    async fetchData() {
      this.loading = true;

      let sign =
        this.options.voucher.sortDesc && this.options.voucher.sortDesc[0]
          ? "-"
          : "";

      let params = {
        page: this.options.voucher.page,
        limit: this.options.voucher.itemsPerPage,
        sort: "-id",
      };

      if (
        this.options.voucher.sortBy.length > 0 &&
        this.options.voucher.sortDesc.length > 0
      ) {
        params.sort =
          sign +
          (this.options.voucher.sortBy && this.options.voucher.sortBy[0]);
      }

      let response = await this.$get("admin/coupon", params);

      this.loading = false;

      if (response.status == 200) {
        this.dataGrid.voucher = response.results.data;
        this.totalData.voucher = parseInt(
          response.results.pagination.total_data
        );
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async fetchDetail(item){
      this.formItemType.data = []
      let params = {
        id: item.id
      };

      this.loading_detail = true;

      let url = "admin/coupon/detail";

      let response = await this.$get(url, params);

      this.loading_detail = false;

      if (response.status == 200) {
        this.detail = response.results.data
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async doSave() {
      let isValid = this.$refs["form-voucher"].validate();

      if (isValid) {
        this.form.image_portrait_url = this.form.image_square_url
        this.form.item_type = this.formItemType.item_for === 'all' ? `all ${this.form.item_type}` : this.form.item_type
        this.form.end_time = `${this.form.end_time}:00`
        this.form.start_time = `${this.form.start_time}:00`
        this.form.item_array = this.form.item_array.map(e => e.id)
        this.$store.state.overlay.show = true;
        this.$store.state.overlay.text = "Menyimpan data voucher...";

        let url = "admin/coupon";
        if (this.form.id > 0) {
          url = "admin/coupon/update";
        }

        let response = await this.$post(url, this.form);

        this.$store.state.overlay.show = false;

        if (response.status == 200) {
          this.$store.state.snackbar = {
            show: true,
            text: "Berhasil menyimpan data voucher",
            color: "#2ecc71",
          };
          this.dialog.voucher = false;

          this.formReset();
          this.$refs["form-voucher"].resetValidation();

          this.validationReset();

          this.fetchData();
        } else if (response.status == 412) {
          this.$store.state.snackbar = {
            show: true,
            text: "Cek kembali form Anda",
            color: "#e74c3c",
          };

          Object.assign(this.validation, response.results.data);
        } else if (response.status == 400) {
          this.$store.state.snackbar = {
            show: true,
            text: response.message,
            color: "#e74c3c",
          };
        }
      }
    },

    async doDelete() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Menghapus voucher...";

      let response = await this.$post("admin/coupon/delete", {
        id: this.form.id
      });

      this.$store.state.overlay.show = false;

      if (response.status == 200) {
        this.$store.state.snackbar = {
          show: true,
          text: "Berhasil menghapus voucher",
          color: "#2ecc71",
        };
        this.dialog.confirmDelete = false;

        this.selected.voucher = [];
        this.fetchData();
      } else if (response.status == 412) {
        this.$store.state.snackbar = {
          show: true,
          text: "Cek kembali form Anda",
          color: "#e74c3c",
        };
      } else if (response.status == 400) {
        this.$store.state.snackbar = {
          show: true,
          text: response.message,
          color: "#e74c3c",
        };
      }
    },
  },
};
</script>

<style>
</style>